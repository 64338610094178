import { useEffect, useState } from 'react';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Tabs } from '@interstate/components/Tabs';
import { QnA } from 'scenes/qna/qna';
import styled from 'styled-components';
import { MFPayload } from 'models/MFPayload';
import { ScannerScene } from 'scenes/scanner/scaner';
import { VehicleInfoUI } from '@checkpoint/common-components';

export default function TRAHomeScene(props: MFPayload) {
  const [customMessage, setCustomMessage] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('0');
  const [handleMenu, setHandleMenu] = useState(false);

  useEffect(() => {
    document.addEventListener('onNotifySameAppSelection', handleOnMenuItemSelection as EventListener);

    return () => {
      document.removeEventListener('onNotifySameAppSelection', handleOnMenuItemSelection as EventListener);
    };
  }, []);

  const handleOnMenuItemSelection = () => {
    setSelectedTab('0');
  };

  return (
    <InterstateThemeProvider
      themeName="Interstate"
      applicationName="TRA Inspection"
      scopeName="TRA Inspection"
      themeRegistries={[interstateThemeRegistry]}
    >
      <StyledBox>
        <StyledGrid container spacing={2}>
          <Grid xs={12} sx={{ marginTop: '5px' }}>
            <Typography tag="span" variant="h1" color="base.color.blue.800">
              TRA Inspection
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography tag="span" variant="h6" color="base.color.blue.800">
              {props.userDetails?.userName} {'-'}
              {props.userDetails?.emailId}
            </Typography>
            <CheckBox
              data-testid="checkbox-1-data-testid"
              id="checkbox-1-id"
              label="Handle Menu"
              name="handle-menu"
              onChange={(event: InterstateOnChangeEvent<CheckBoxEventValue>) => {
                setHandleMenu(event.target.value.checked);
                props.showAlertForMenuSwitch?.(
                  event.target.value.checked,
                  customMessage ? 'Your inspection progress since the last save will be lost.' : ''
                );
              }}
              value="1"
            />
            <CheckBox
              data-testid="checkbox-2-data-testid"
              id="checkbox-2-id"
              label="Send Custom Message"
              name="custom-message"
              onChange={(event: InterstateOnChangeEvent<CheckBoxEventValue>) => {
                setCustomMessage(event.target.value.checked);
                props.showAlertForMenuSwitch?.(
                  handleMenu,
                  event.target.value.checked ? 'Your inspection progress since the last save will be lost.' : ''
                );
              }}
              value="1"
            />
          </Grid>

          <Tabs
            data-testid="default-data-testid"
            id="default-id"
            onActivate={value => {
              setSelectedTab(value);
            }}
            tabs={[
              {
                component: <ScannerScene {...props} />,
                label: 'Scanner',
                value: '0',
                sx: { color: 'base.color.blue.800' },
                active: selectedTab === '0'
              },
              {
                component: (
                  <VehicleInfoUI
                    vehicleInfo={{
                      workOrderNumber: '1234567',
                      companyName: 'BENTLEY NAPLES',
                      manheimAccountNumber: '5263548',
                      vin: '4JGDA5HB4JB203752',
                      reading: '37703',
                      units: 'Miles',
                      shortDescription: '2018 Mercendes-Benz GLE 350',
                      interiorColor: 'Black',
                      exteriorColor: 'Grey'
                    }}
                    onContinue={() => {}}
                    onBack={() => {}}
                  />
                ),
                label: 'Vehicle Info',
                value: '1',
                sx: { color: 'base.color.blue.800' },
                active: selectedTab === '1'
              },
              {
                component: <QnA {...props} />,
                label: 'QnA',
                value: '2',
                sx: { color: 'base.color.blue.800' },
                active: selectedTab === '2'
              },
              {
                component: (
                  <div>
                    Photos content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Photos',
                value: '3',
                sx: { color: 'base.color.blue.800' },
                active: selectedTab === '3'
              },
              {
                component: (
                  <div>
                    Review content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Review',
                value: '4',
                sx: { color: 'base.color.blue.800' },
                active: selectedTab === '4'
              }
            ]}
          />
        </StyledGrid>
      </StyledBox>
    </InterstateThemeProvider>
  );
}

const StyledBox = styled(Box)`
  background: white;
  min-height: 100vh;
`;

const StyledGrid = styled(Grid)`
  width: 90%;
  margin: auto !important;
`;
