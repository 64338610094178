import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { TextInput } from '@interstate/components/TextInput';
import { MFPayload } from 'models/MFPayload';
import photos from 'resources/entry_images.json';
import { FyusePayload, FyuseImageGuide, FyuseEvent } from 'models/FyusePayload';
import { Typography } from '@interstate/components/Typography';
import { CaptureResult, ScanResult } from 'models/EventBody';

export const ScannerScene = (props: MFPayload) => {
  const [workOrder, setWorkOrder] = useState<string | undefined>();
  const [fyuseSessionPath, setFyuseSessionPath] = useState<string | undefined>();
  const [fyuseSessionID, setFyuseSessionID] = useState<string | undefined>();
  const [progressValue, setProgressValue] = useState<number | undefined>();

  useEffect(() => {
    document.addEventListener('onScanComplete', handleOnScanComplete as EventListener);

    document.addEventListener('onFyuseCaptureComplete', handleOnCaptureComplete as EventListener);

    document.addEventListener('onFyuseEvent', handleOnFyuseEvent as EventListener);

    return () => {
      document.removeEventListener('onScanComplete', handleOnScanComplete as EventListener);

      document.removeEventListener('onFyuseCaptureComplete', handleOnCaptureComplete as EventListener);

      document.removeEventListener('onFyuseEvent', handleOnFyuseEvent as EventListener);
    };
  }, []);

  const handleOnScanComplete = (event: CustomEvent<ScanResult>) => {
    console.log(event.detail.result);
    setWorkOrder(event.detail.result);
  };

  const handleOnCaptureComplete = (event: CustomEvent<CaptureResult>) => {
    setFyuseSessionPath(event.detail.sessionPath);
  };

  const handleOnFyuseEvent = (event: CustomEvent<string>) => {
    const fyuseEvent: FyuseEvent = JSON.parse(event.detail);
    setFyuseSessionID(fyuseEvent.sessionId);
    setProgressValue(fyuseEvent.progress);
  };

  const handleScan = () => {
    props.openScanner?.();
  };

  const handleCapture = () => {
    const fyusePhotos: FyuseImageGuide[] = [];
    photos.forEach(photo => {
      const photoData: FyuseImageGuide = {
        title: photo.name,
        guideURL: photo.attributes.customGuideDrawable,
        category: photo.category,
        description: photo.attributes.caption,
        internalDescription: '',
        required: photo.required,
        uid: 1234,
        captureMode: photo.attributes.captureMode,
        angleCode: photo.angleCode
      };
      fyusePhotos.push(photoData);
    });
    const fyusePayload: FyusePayload = {
      photos: fyusePhotos,
      media: [],
      metaData: {
        location: 'QLM1',
        workOrder: workOrder || ''
      }
    };
    props.openFyuseCapture?.(fyusePayload);
    setFyuseSessionPath(undefined);
    setFyuseSessionID(undefined);
    setProgressValue(undefined);
  };

  const handleUpload = () => {
    setFyuseSessionID(undefined);
    setProgressValue(undefined);
    const metaData = {
      location: 'QLM1',
      workOrder: workOrder || '',
      appName: 'Checkpoint',
      channel: 'WHOLESALE',
      userID: props.userDetails?.emailId
    };
    if (fyuseSessionPath) props.startFyuseUpload?.(fyuseSessionPath, metaData);
  };

  const handleIM = () => {
    const fyusePhotos: FyuseImageGuide[] = [];
    photos.forEach(photo => {
      const photoData: FyuseImageGuide = {
        title: photo.name,
        guideURL: photo.attributes.customGuideDrawable,
        category: photo.category,
        description: photo.attributes.caption,
        internalDescription: '',
        required: photo.required,
        uid: 1234,
        captureMode: photo.attributes.captureMode,
        angleCode: photo.angleCode
      };
      fyusePhotos.push(photoData);
    });
    const fyusePayload: FyusePayload = {
      photos: fyusePhotos,
      media: [],
      metaData: {
        location: 'QLM1',
        workOrder: workOrder || ''
      },
      sessionPath: fyuseSessionPath
    };
    props.openFyuseCapture?.(fyusePayload);
  };

  return (
    <StyledBox>
      <StyledGrid container spacing={2}>
        <Grid xs={12} sx={{ marginTop: '30px' }}>
          <TextInput
            id="scanner_wo_input"
            data-testid="scanner_wo_input"
            name="Work Order Number"
            maxLength={7}
            placeholder="Work Order or VIN"
            value={workOrder}
            onChange={val => {
              setWorkOrder(val.target.value);
            }}
          />
        </Grid>
        <Grid xs={12} sx={{ marginTop: '10px' }}>
          <PrimaryButon block type="submit" onClick={handleScan}>
            SCAN
          </PrimaryButon>
        </Grid>
        {workOrder ? (
          <Grid xs={12}>
            <PrimaryButon block type="submit" onClick={handleCapture}>
              CAPTURE
            </PrimaryButon>
          </Grid>
        ) : null}
        {fyuseSessionPath ? (
          <>
            <Grid xs={12}>
              <PrimaryButon block type="submit" onClick={handleUpload}>
                UPLOAD
              </PrimaryButon>
            </Grid>
            {progressValue ? (
              <Grid xs={12}>
                <progress value={progressValue} max={100} style={{ width: '100%', height: '20px' }} />
              </Grid>
            ) : null}
            {fyuseSessionID ? (
              <>
                <Grid xs={12}>
                  <PrimaryButon block type="submit" onClick={handleIM}>
                    FYUSE IM
                  </PrimaryButon>
                </Grid>
                <Grid xs={12}>
                  <Typography tag="span" variant="h6" color="base.color.blue.800">
                    {'Fyuse Session ID - '} {fyuseSessionID}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </>
        ) : null}
      </StyledGrid>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background: white;
  min-height: 100vh;
`;
const StyledGrid = styled(Grid)`
  width: 90%;
  margin: auto !important;
`;
const PrimaryButon = styled(Button)``;
